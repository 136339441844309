import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="content">
        <div className="tirc-company">
            <Link to="/">
                <img src="/tirc.png" alt="Company Logo" className="tirc-logo" />
            </Link>
            <p className="slogan">Innovation, Reimagined.<br />Empowering the Infinite You.</p>
            <hr />
            <p className="description">We are living in a time of unprecedented excitement and innovation, where the possibilities are endless and the opportunities are vast. We are on the cusp of a technological revolution that will change the world as we know it, and we are thrilled to be a part of it. From the rise of automation and AI, to the development of new technologies that will make things easier and more enjoyable, we are committed to being at the forefront of this revolution. We see a future where technology is not just a tool, but a partner in journey, helping us to learn and grow in ways that were previously unimaginable. And we are committed to being a part of it. Our mission is to bring the best of comfort, excitement, and innovation together, making every moment more inspiring and enjoyable. </p>
        </div>
        <div className="centered-link">
            <Link to="https://www.theemphasiselevated.com" target="_blank" rel="noreferrer" className="link-style">
                <div>
                    <h1 className="header-style">
                        <img src="/e3-fav.png" alt="Thee3 logo" className="logo-style" />
                        <span className="header-text">The Emphasis Elevated <sup>(Thee<sup>3</sup>)</sup></span>
                    </h1>
                    <h1 className="header-style-sm">
                        <img src="/e3-fav.png" alt="Thee3 logo" className="logo-style" /><br /><br />
                        <span className="header-text">The<br/> Emphasis<br/> Elevated <br/>(Thee<sup>3</sup>)</span>
                    </h1>
                </div>
            </Link>
        </div>
    </div>
  );
}

export default Home;